import {
  createApi,
  fetchBaseQuery,
  FetchBaseQueryError,
} from "@reduxjs/toolkit/query/react";
import { setGlobalOrgId, setGlobalOrgIds } from "../global/global.reducer";
import { decrypt, pendingActionsTypes } from "../../utils/utils";
import { VerifyOTPResponse } from "./types/verify-otp.types";
import { SingleOrg } from "./types/get-org.types";
import { RootState } from "../store";
import { isDeletableOrg, selectNewOrgs } from "./api.selector";
import { GetCallInsightsPayload, Insights } from "./types/insights.types";
import { TaskType } from "./types/task-types.types";
import { setEditMode } from "../global/global.reducer";
import {
  setGender,
  setLanguage,
  setMessage,
  setStep,
  toggleCopyCallerTuneModal,
  toggleEnableSave,
} from "../../store/global/global.reducer";
import { GetTasksPayload, GetTasksResponse } from "./types/task-search.types";
import {
  GetCustomersPayload,
  GetCustomersResponse,
} from "./types/customer-search.types";
import {
  ImportCustomerPayload,
  ImportCustomerResponse,
} from "./types/import-customers";
import { CreateTaskPayload } from "./types/create-task.types";
import { UpdateTaskPayload } from "./types/update-task.types";
import {
  GetCustomerInsightsPayload,
  GetCustomerInsightsResponse,
} from "./types/get-customer-insights.types";
import { TaskFilter } from "./types/task-filters.types";
import { CustomerFilter } from "./types/customer-filters.types";
import { CustomerBulkUpdatePayload } from "./types/customer-bulk-update.types";
import { LoginResponse } from "./types/login.types";
import { UpdateContactPayload } from "./types/update-contact.types";
import { CreateLeadStagePayload } from "./types/create-lead-stage.types";
import { LeadStage } from "./types/lead-stage.types";
import {
  UpdateLeadGroupPayload,
  UpdateLeadGroupResponse,
} from "./types/update-lead-group.types";
import {
  CreateLeadGroupPayload,
  CreateLeadGroupResponse,
} from "./types/create-lead-group.types";
import { LeadGroup } from "./types/lead-group.types";
import {
  SearchNotesPayload,
  SearchNotesResponse,
} from "./types/note-search.types";
import { ApiKey } from "./types/org-api-key.types";
import { Category } from "./types/category.types";
import {
  ChangeLeadStagePositionPayload,
  ChangeLeadStagePositionResponse,
} from "./types/change-lead-stage-position.types";
import {
  UpdateLeadStagePayload,
  UpdateLeadStageResponse,
} from "./types/update-lead-stage.types";
import { LeadStageType } from "./types/get-lead-stage.types";
import { LeadGroupType } from "./types/get-lead-groups.types";
import { OrgLabel } from "./types/org-labels.types";
import { UpdateOrgLabelPayload } from "./types/update-org-label.types";
import {
  CallHistoryPayload,
  CallHistoryResponse,
  CustomerCallHistoryPayload,
} from "./types/call-history.types";
import { Member } from "./types/member.types";
import { CallRecording } from "./types/call-recording.types";
import {
  CustomerImportPayload,
  CustomerImportResponse,
} from "./types/customer-import.types";
import {
  CreateContactPayload,
  CreateContactResponse,
} from "./types/create-contact.types";
import { Customer } from "./types/get-customer.types";
import { CreateProductPayload } from "./types/create-product.types";
import { UpdateProductPayload } from "./types/update-product.types";
import { CreateDailyTopupOrderPayload } from "./types/create-daily-topup-order.types";
import { AssignProductPayload } from "./types/assign-product.types";
import { UnassignProductPayload } from "./types/unassign-product.types copy";
import { CustomerActivity } from "./types/customer-activities.types";
import { CreateNotePayload } from "./types/create-note.types";
import { UpdateNotePayload } from "./types/update-note.types";
import { OrgUser } from "./types/get-org-users.types";
import { FacebookPage } from "./types/get-facebook-pages.types";
import { LinkFacebookPagePayload } from "./types/link-facebook-page.types";
import { GetOnboardUIConfigResponse } from "./types/get-onboard-ui-config.types";
import {
  OnboardOrgPayload,
  OnboardOrgResponse,
} from "./types/onboard-org.types";
import { UpdateUserPayload } from "./types/update-user.types";
import { UpdateOrgPayload, UpdateOrgResponse } from "./types/update-org.types";
import { StartTrialPayload } from "./types/start-trial.types";
import {
  VerifyInviteCodePayload,
  VerifyInviteCodeResponse,
} from "./types/verify-invite-code.types";
import { UsersSyncResponse } from "./types/users-sync.types";
import { GetUserRecentDeviceResponse } from "./types/get-user-recent-device.types";
import { GetProductsPayload } from "./types/get-products.types";
import {
  ClickToCallPayload,
  ClickToCallResponse,
} from "./types/click-to-call.types";
import {
  GetTasksCategoriesPayload,
  GetTasksCategoriesResponse,
} from "./types/get-tasks-categories.types";
import { TaskData } from "./types/get-task.types";
import { LoginOrgResponse } from "./types/login-org.types";
import {
  IntegrationSetting,
  OrgIntegrationSettings,
  OrgIntegrationSettingsPayload,
} from "./types/get-org-integration-settings.types";
import {
  CreateOrgIntegrationPayload,
  CreateOrgIntegrationResponse,
} from "./types/create-org-integration.types";
import { UpdateOrgIntegrationPayload } from "./types/update-org-integration.types";
import { Org } from "./types/get-orgs.types";
import {
  GetPublicURLPayload,
  GetPublicURLResponse,
} from "./types/get-public-url.types";
import { Summary } from "./types/get-summaries.types";
import { GetMemberResponse } from "./types/get-member.types";
import {
  GetTaskInsightsPayload,
  TaskInsights,
} from "./types/get-task-insights.types";
import {
  CreateEmailIntegrationPayload,
  CreateEmailIntegrationResponse,
} from "./types/create-email-integration.types";
import { EmailIntegration } from "./types/get-email-integrations.types";
import { GetLastEmailResponse } from "./types/get-last-email.types";
import { AvailablePlansResponse } from "./types/available-plans.types";
import { GetNumberDetailsResponse } from "./types/get-number-details.types";
import { GetReservedNumberResponse } from "./types/get-reserved-number.types";
import { GetOrderResponse } from "./types/get-order.types";
import { Order as PendingOrder } from "./types/get-pending-order.types";
import {
  SearchAvailableNumbersPayload,
  SearchAvailableNumbersResponse,
} from "./types/search-available-number.types";
import { OrgSubscription } from "./types/get-org-subscriptions.types";
import { CreateOrgResponse } from "./types/create-org.types";
import { OrgSettings } from "./types/get-org-settings.types";
import { CancelInvitePayload } from "./types/cancel-invite.types";
import { ChangeRoleResponse } from "./types/change-role.types";
import { GetPermissionsResponse } from "./types/get-permissions.types";
import { GetInvitationCountResponse } from "./types/get-invitation-count.types";
import {
  InviteMemberPayload,
  InviteMemberResponse,
} from "./types/invite-member.types";
import { Presence } from "./types/get-presence.types";
import { GetCallerTuneResponse } from "./types/get-caller-tune.types";
import {
  UpdateOrgSettingsPayload,
  UpdateOrgSettingsResponse,
} from "./types/update-org-settings.types";
import {
  PreviewCallerTunePayload,
  PreviewCallerTuneResponse,
} from "./types/preview-caller-tune.types";
import {
  SetCallerTunePayload,
  SetCallerTuneResponse,
} from "./types/set-caller-tune.types";
import { transformToNotesRows } from "../../utils/notes-utils";
import { GetCustomerNotesResponse } from "./types/get-customer-notes.types";
import { WabaToken } from "./types/get-waba-tokens.types";
import {
  CreateTopupOrderPayload,
  CreateTopupOrderResponse,
} from "./types/create-topup-order.types";
import { CancelOrderPayload } from "./types/cancel-order.types";
import { NumbersReservePayload } from "./types/numbers-reserve.types";
import {
  CreatePurchaseOrderPayload,
  CreatePurchaseOrderResponse,
} from "./types/create-purchase-order.types";
import { CreateRenewOrderPayload } from "./types/create-renew-order.types";
import { sourceTypeOptions } from "../../pages/contacts/create-update-contact-modal.component";
import { GetSubscriptionPlanResponse } from "./types/get-subscription-plan.types";
import { LeadsCount } from "./types/get-all-leads-count.types";
import { convertToSearchParams } from "../../utils/convert-to-search-params";
import { UpdateEmailIntegrationPayload } from "./types/update-email-integration.types";
import { FacebookForm } from "./types/get-facebook-forms.types";
import { FacebookFormData } from "./types/get-facebook-form.types";
import {
  CreateMarketingIntegrationPayload,
  CreateMarketingIntegrationResponse,
} from "./types/create-marketing-integration.types";
import { MarketingIntegration } from "./types/get-marketing-integration.types";
import { FacebookAccount } from "./types/get-facebook-account.types";
import {
  UpdateMarketingIntegrationPayload,
  UpdateMarketingIntegrationResponse,
} from "./types/update-marketing-integration.types";
import { Email } from "./types/get-emails.types";
import { FacebookLog } from "./types/facebook-log.types";
import { IndiaMartLog } from "./types/indiamart-logs.types";
import { ActionSetting } from "./types/action-setting.types";
import {
  AutomationLog,
  GetAutomationLogsPayload,
} from "./types/get-automation-logs.types";
import { TemplateVariable } from "./types/template-variable.types";
import { InternalLeadStatus } from "./types/get-internal-lead-status.types";
import { TalktimeDetails } from "./types/get-talktime-details.types";
import { GetCRMSettingsReponse } from "./types/crm-settings.types";
import { GetAllPlansResponse } from "./types/get-all-plans.types";
import { Job } from "./types/import-recent-contacts";
import { CustomField } from "./types/custom-fields.types";
import {
  CreateCustomFieldPayload,
  CreateCustomFieldResponse,
} from "./types/create-custom-fields.types";
import {
  UpdateCustomFieldPayload,
  UpdateCustomFieldResponse,
} from "./types/update-custom-fields.types";
import { DeleteCustomFieldResponse } from "./types/delete-custom-fields.types";
import { AllowedCustomField } from "./types/allowed-custom-fields.types";
import { CustomerCustomFieldResponse } from "./types/get-important-field.types";
import {
  CreateImportantCustomFieldPayload,
  CreateImportantCustomFieldResponse,
} from "./types/create-important-custom-field.types";
import {
  UpdateImportantCustomFieldPayload,
  UpdateImportantCustomFieldResponse,
} from "./types/update-important-custom-fields.types";
import { DeleteImportantCustomFieldResponse } from "./types/delete-important-custom-field.types";
import { DragonflyAccount } from "./types/get-dragonfly-accounts.types";

export type GenericAPIResponse<T> = {
  data: T;
  message: string;
};

const API_HOST = process.env.REACT_APP_API_HOST;

export const api = createApi({
  reducerPath: "api",
  baseQuery: fetchBaseQuery({
    baseUrl: API_HOST,
    credentials: "include",
  }),
  endpoints: (builder) => ({
    verifyInviteCode: builder.mutation<
      VerifyInviteCodeResponse,
      VerifyInviteCodePayload
    >({
      query: (body) => ({
        url: `/org-admin/invite-code/verify`,
        method: "post",
        body,
      }),
      transformResponse: (
        response: GenericAPIResponse<VerifyInviteCodeResponse>
      ) => response.data,
    }),
    login: builder.mutation<LoginResponse, string>({
      query: (phone) => ({
        url: "/auth/login",
        method: "post",
        body: { phone },
      }),
      transformResponse: (response: GenericAPIResponse<LoginResponse>) =>
        response.data,
    }),
    verifyOtp: builder.mutation<
      VerifyOTPResponse,
      {
        phone: string;
        code: string;
      }
    >({
      query: (payload) => ({
        url: "/auth/org-admin/verify-otp",
        method: "post",
        body: payload,
      }),
      transformResponse: (response: GenericAPIResponse<VerifyOTPResponse>) =>
        response.data,
    }),
    logout: builder.mutation<any, void>({
      query: () => ({
        url: "/auth/logout",
      }),
      transformResponse: (response: GenericAPIResponse<any>) => {
        localStorage.removeItem("userId");
        localStorage.removeItem("orgId");
        return response.data;
      },
    }),
    usersSync: builder.query<UsersSyncResponse, void>({
      query: () => ({ url: "/users/sync" }),
      transformResponse: (response: GenericAPIResponse<UsersSyncResponse>) =>
        response.data,
      onQueryStarted: async (_, { dispatch, queryFulfilled, getState }) => {
        try {
          const { data } = await queryFulfilled;
          const userId = data?.user?.id ? String(data.user.id) : "";
          if (userId) {
            localStorage.setItem("userId", userId);
          } else {
            localStorage.removeItem("userId");
          }
          const defaultOrgId = data?.org?.id ? String(data.org.id) : "";
          if (defaultOrgId) {
            localStorage.setItem("orgId", defaultOrgId);
          } else {
            localStorage.removeItem("orgId");
          }
        } catch (err) {}
      },
    }),
    getWabaTokens: builder.query<WabaToken[], void>({
      query: () => ({
        url: "/whatsapp/heltar/org-admin/token",
      }),
      transformResponse: (response: GenericAPIResponse<WabaToken[]>) =>
        response.data,
    }),
    getOrgs: builder.query<Org[], void>({
      query: () => ({ url: "/org-admin/org" }),
      transformResponse: (response: GenericAPIResponse<Org[]>) => response.data,
      onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
        try {
          const response = await queryFulfilled;
          if (Array.isArray(response?.data) && response?.data?.length) {
            const orgIds = response?.data?.map((org) => org.id) || [];
            dispatch(setGlobalOrgId(orgIds?.[0]));
            dispatch(setGlobalOrgIds(orgIds));
          }
        } catch (e) {}
      },
    }),
    getSummaries: builder.query<Summary[], void>({
      query: () => ({
        url: "/org-admin/subscription/summary",
      }),
      transformResponse: (response: GenericAPIResponse<Summary[]>) =>
        response.data,
      onQueryStarted: async (_, { queryFulfilled }) => {
        try {
          await queryFulfilled;
        } catch (e) {}
      },
    }),
    loginOrg: builder.query<LoginOrgResponse, number>({
      query: (org_id) => ({
        url: "/auth/org-admin/login-org",
        method: "post",
        body: {
          org_id,
        },
      }),
      transformResponse: (response: GenericAPIResponse<LoginOrgResponse>) =>
        response.data,
    }),
    getPendingOrder: builder.query<PendingOrder | null, void>({
      query: () => ({
        // url: "/org-admin/orders/pending",
        url: "/orders/pending",
      }),
      transformResponse: (
        response: GenericAPIResponse<PendingOrder | null>
      ) => {
        const pendingOrder = response.data;
        if (pendingOrder?.external_order_status === "paid") {
          return null;
        }
        return pendingOrder;
      },
    }),
    getReservedNumber: builder.query<GetReservedNumberResponse, void>({
      query: () => ({
        url: "/numbers/reserve",
      }),
      transformResponse: (
        response: GenericAPIResponse<GetReservedNumberResponse>
      ) => response.data,
    }),
    cancelOrder: builder.mutation<any, CancelOrderPayload>({
      query: (payload) => {
        const { order_id, ...body } = payload;
        return {
          url: `/orders/cancel/${order_id}`,
          method: "post",
          body,
        };
      },
      transformResponse: (response: GenericAPIResponse<any>) => response.data,
    }),
    deleteOrg: builder.mutation<any, number>({
      async queryFn(_arg, _queryApi, _extraOptions) {
        const orgId = _arg;
        const { dispatch, getState } = _queryApi;
        const summaries = await api.endpoints.getSummaries.initiate(undefined, {
          forceRefetch: true,
        })(dispatch, getState, undefined);
        const orgs = await api.endpoints.getOrgs.initiate(undefined, {
          forceRefetch: true,
        })(dispatch, getState, undefined);
        const summary = summaries?.data?.find((s) => s.org_id === orgId);
        const org = orgs?.data?.find((o) => o.id === orgId);
        const isDeletable = isDeletableOrg({
          has_subscription: !!summary?.subscription,
          has_superfone_number: !!org?.superfone_number,
          is_active: !!summary?.is_active,
          is_trial: !!summary?.is_trial,
          is_trial_active: !!summary?.is_trial_active,
        });
        if (isDeletable && org && orgs?.data?.length !== 1) {
          await fetch(`${API_HOST || ""}/org-admin/org/${orgId}`, {
            method: "DELETE",
            credentials: "include",
          });
        }
        return { data: isDeletable as any };
      },
    }),
    getCallInsights: builder.query<Insights, GetCallInsightsPayload>({
      query: (payload) => ({
        url: `/org-admin/call-history/insights`,
        method: "post",
        body: {
          from: payload.from,
          to: payload.to,
          ...(payload?.user_id ? { user_id: payload.user_id } : {}),
          org_id: payload?.org_id || null,
        },
      }),
      transformResponse: (response: GenericAPIResponse<Insights>) => {
        return response.data;
      },
    }),
    getCallHistoryInsightsArray: builder.query<
      (Insights | null)[],
      GetCallInsightsPayload[]
    >({
      async queryFn(_arg, _queryApi, _extraOptions) {
        const { dispatch, getState } = _queryApi;
        const promises = _arg.map((payload) =>
          api.endpoints.getCallInsights.initiate(payload)(
            dispatch,
            getState,
            undefined
          )
        );
        const responses: (Insights | null)[] = [];
        const result = await Promise.allSettled(promises);
        result?.map((response) => {
          const { status } = response;
          if (status === "fulfilled" && response?.value?.data) {
            responses.push(response.value.data);
          } else {
            responses.push(null);
          }
          return null;
        });
        return { data: responses || [] };
      },
    }),
    getOrg: builder.query<SingleOrg, number>({
      query: (org_id) => ({
        url: `/org-admin/org/${org_id}`,
      }),
      transformResponse: (response: GenericAPIResponse<SingleOrg>) => {
        return response.data;
      },
    }),
    getOrgSettings: builder.query<OrgSettings[], number>({
      query: (orgId) => ({
        url: `/org-admin/settings/org/${orgId}`,
      }),
      transformResponse: (response: GenericAPIResponse<OrgSettings[]>) => {
        return response.data;
      },
    }),
    updateOrgSettings: builder.mutation<
      UpdateOrgSettingsResponse,
      UpdateOrgSettingsPayload
    >({
      query: (payload) => {
        const { orgId, ...body } = payload;
        return {
          url: `/org-admin/settings/org/${orgId}`,
          method: "put",
          body,
        };
      },
      transformResponse: (
        response: GenericAPIResponse<UpdateOrgSettingsResponse>
      ) => {
        return response.data;
      },
    }),
    getOrgUsers: builder.query<OrgUser[], number>({
      query: (org_id) => ({
        url: `/org-admin/users/org/${org_id}?state=active`,
      }),
      transformResponse: (response: GenericAPIResponse<OrgUser[]>) => {
        return response.data;
      },
    }),
    getOrgLabels: builder.query<OrgLabel[], { orgId: number; text?: string }>({
      query: (payload) => ({
        url: `/org-admin/label/org/${payload.orgId}${
          payload.text ? `?text=${payload.text}` : ""
        }`,
      }),
      transformResponse: (response: GenericAPIResponse<OrgLabel[]>) => {
        return response.data;
      },
    }),
    createOrgLabel: builder.mutation<
      OrgLabel,
      {
        org_id: number;
        title: string;
        colour: string;
        text_colour: string;
      }
    >({
      query: (body) => ({
        url: `/org-admin/label`,
        method: "post",
        body,
      }),
      transformResponse: (response: GenericAPIResponse<OrgLabel>) => {
        return response.data;
      },
    }),
    updateOrgLabel: builder.mutation<OrgLabel, UpdateOrgLabelPayload>({
      query: (payload) => {
        const { id, ...body } = payload;
        return {
          url: `/org-admin/label/${id}`,
          method: "put",
          body,
        };
      },
      transformResponse: (response: GenericAPIResponse<OrgLabel>) => {
        return response.data;
      },
    }),
    deleteOrgLabel: builder.mutation<any, { labelId: number; org_id: number }>({
      query: (payload) => {
        const { labelId, ...body } = payload;
        return {
          url: `/org-admin/label/${labelId}`,
          method: "delete",
          body,
        };
      },
      transformResponse: (response: GenericAPIResponse<any>) => {
        return response.data;
      },
    }),
    updateOrg: builder.mutation<UpdateOrgResponse, UpdateOrgPayload>({
      query: (payload) => {
        const { orgId, ...body } = payload;
        return {
          url: `org-admin/org/${orgId}`,
          body,
          method: "put",
        };
      },
      transformResponse: (response: GenericAPIResponse<UpdateOrgResponse>) => {
        return response.data;
      },
      onQueryStarted: async (_, { dispatch, getState, queryFulfilled }) => {
        await queryFulfilled;

        const urlArray = window.location.href.split("/orgs/");
        //Assuming /orgs/encodedOrgId
        if (urlArray?.[1]) {
          const encodedOrgId = urlArray?.[1] || "";
          const orgId = Number(decrypt(encodedOrgId));
          await api.endpoints.getOrg.initiate(orgId, {
            forceRefetch: true,
          })(dispatch, getState, undefined);
        }
        await api.endpoints.getOrgs.initiate(undefined, {
          forceRefetch: true,
        })(dispatch, getState, undefined);
        await api.endpoints.getSummaries.initiate(undefined, {
          forceRefetch: true,
        })(dispatch, getState, undefined);
        dispatch(setEditMode(false));
      },
    }),
    createOrg: builder.mutation<
      CreateOrgResponse,
      {
        name: string;
        email?: string;
        metadata?: any;
      }
    >({
      query: (body) => ({
        url: `/org-admin/org`,
        method: "post",
        body,
      }),
      transformResponse: (response: GenericAPIResponse<CreateOrgResponse>) => {
        return response.data;
      },
    }),
    getOrgSubscriptions: builder.query<OrgSubscription[], number>({
      query: (org_id) => ({
        url: `/org-admin/subscription/org/${org_id}`,
      }),
      transformResponse: (response: GenericAPIResponse<OrgSubscription[]>) => {
        return response.data;
      },
    }),
    getOrgApiKeys: builder.query<ApiKey[], number>({
      query: (org_id) => ({
        url: `/org-admin/org/api-keys/${org_id}`,
      }),
      transformResponse: (response: GenericAPIResponse<ApiKey[]>) => {
        return response.data;
      },
    }),
    addOrgApiKey: builder.mutation<ApiKey, { org_id: number; name: string }>({
      query: (payload) => {
        const { org_id, ...body } = payload;
        return {
          url: `/org-admin/org/api-keys/${org_id}`,
          method: "post",
          body,
        };
      },
      transformResponse: (response: GenericAPIResponse<ApiKey>) => {
        return response.data;
      },
      onQueryStarted: async (
        payload,
        { dispatch, queryFulfilled, getState }
      ) => {
        try {
          await queryFulfilled;
          api.endpoints.getOrgApiKeys.initiate(payload.org_id, {
            forceRefetch: true,
          })(dispatch, getState, undefined);
        } catch (e) {}
      },
    }),
    deleteOrgApiKey: builder.mutation<
      ApiKey,
      { org_id: number; api_key: string }
    >({
      query: (payload) => {
        const { org_id, ...body } = payload;
        return {
          url: `/org-admin/org/api-keys/${org_id}`,
          method: "delete",
          body,
        };
      },
      transformResponse: (response: GenericAPIResponse<ApiKey>) => {
        return response.data;
      },
      onQueryStarted: async (
        payload,
        { dispatch, queryFulfilled, getState }
      ) => {
        try {
          await queryFulfilled;
          api.endpoints.getOrgApiKeys.initiate(payload.org_id, {
            forceRefetch: true,
          })(dispatch, getState, undefined);
        } catch (e) {}
      },
    }),
    getAllMembers: builder.query<Member[], void>({
      query: () => ({ url: "/org-admin/users" }),
      transformResponse: (response: GenericAPIResponse<Member[]>) => {
        return response.data;
      },
    }),
    getPresenceOfMembers: builder.query<Presence[], number[]>({
      async queryFn(_arg, _queryApi, _extraOptions) {
        const { dispatch, getState } = _queryApi;
        const promises = _arg.map((orgId) =>
          api.endpoints.getPresence.initiate(orgId)(
            dispatch,
            getState,
            undefined
          )
        );
        const result = await Promise.allSettled(promises);
        const presences: Presence[] = [];
        result.map((response) => {
          const { status } = response;
          if (status === "fulfilled") {
            const s = response.value;
            presences.push(s.data as unknown as Presence);
          }
          return null;
        });
        return { data: presences as Presence[] };
      },
    }),
    getStaffTableData: builder.query<null, void>({
      async queryFn(_arg, _queryApi, _extraOptions) {
        const { dispatch, getState } = _queryApi;
        await api.endpoints.getAllMembers.initiate()(
          dispatch,
          getState,
          undefined
        );
        const orgsResponse = await api.endpoints.getOrgs.initiate()(
          dispatch,
          getState,
          undefined
        );
        const orgs = orgsResponse?.data || [];
        await api.endpoints.getPresenceOfMembers.initiate(
          orgs.map((o) => o.id)
        )(dispatch, getState, undefined);
        return { data: null };
      },
    }),
    getMember: builder.query<GetMemberResponse, number>({
      query: (staffId) => ({
        url: `/org-admin/users/${staffId}`,
      }),
      transformResponse: (response: GenericAPIResponse<GetMemberResponse>) => {
        return response.data;
      },
      onQueryStarted: async (_, { dispatch, queryFulfilled, getState }) => {
        try {
          const data = await queryFulfilled;
          const orgId = data?.data?.orgs?.[0]?.id || 0;
          if (orgId) {
            api.endpoints.getPresence.initiate(orgId)(
              dispatch,
              getState,
              undefined
            );
          }
        } catch (e) {}
      },
    }),
    getInvitationCount: builder.query<GetInvitationCountResponse, number>({
      query: (orgId) => ({
        url: `/org-admin/users/team-details/org/${orgId}`,
      }),
      transformResponse: (
        response: GenericAPIResponse<GetInvitationCountResponse>
      ) => {
        return response.data;
      },
    }),
    removeMember: builder.mutation<
      boolean,
      { org_id: number; user_id: number }
    >({
      query: (payload) => ({
        url: `/org-admin/users/leave`,
        method: "post",
        body: payload,
      }),
      transformResponse: (response: GenericAPIResponse<boolean>) => {
        return true;
      },
      onQueryStarted: async (_arg, { dispatch, queryFulfilled, getState }) => {
        const { org_id } = _arg;
        const state = getState() as unknown as RootState;
        await queryFulfilled;
        await api.endpoints.getOrgUsers.initiate(org_id, {
          forceRefetch: true,
        })(dispatch, getState, undefined);
        await api.endpoints.getInvitationCount.initiate(org_id, {
          forceRefetch: true,
        })(dispatch, getState, undefined);
        await api.endpoints.getOrgUsers.initiate(state.global.selectedOrgId, {
          forceRefetch: true,
        })(dispatch, getState, undefined);
      },
    }),
    inviteMember: builder.mutation<InviteMemberResponse, InviteMemberPayload>({
      query: (payload) => ({
        url: `/org-admin/users/invite`,
        method: "post",
        body: payload,
      }),
      transformResponse: (
        response: GenericAPIResponse<InviteMemberResponse>
      ) => {
        return response.data;
      },
      onQueryStarted: async (_arg, { dispatch, queryFulfilled, getState }) => {
        const { org_id, role } = _arg;
        const state = getState() as unknown as RootState;
        try {
          const data = await queryFulfilled;
          const user_id = data?.data?.id || 0;
          if (!!role && role !== "member") {
            api.endpoints.changeUserRole.initiate({
              org_id,
              role,
              user_id,
            })(dispatch, getState, undefined);
          }
          const newOrgs = selectNewOrgs(state);
          const exists = newOrgs.find((org) => org.id === org_id);
          if (exists?.actions?.includes(pendingActionsTypes.addStaff)) {
            await api.endpoints.getInvitationCount.initiate(org_id, {
              forceRefetch: true,
            })(dispatch, getState, undefined);
          }
        } catch (e) {}
      },
    }),
    cancelInvite: builder.mutation<boolean, CancelInvitePayload>({
      query: (payload) => ({
        url: `/org-admin/users/cancel-invite`,
        method: "post",
        body: payload,
      }),
      transformResponse: (response: GenericAPIResponse<boolean>) => {
        return true;
      },
      onQueryStarted: async (_arg, { dispatch, queryFulfilled, getState }) => {
        const { org_id } = _arg;
        const state = getState() as unknown as RootState;
        await queryFulfilled;
        await api.endpoints.getOrgUsers.initiate(org_id, {
          forceRefetch: true,
        })(dispatch, getState, undefined);
        await api.endpoints.getInvitationCount.initiate(org_id, {
          forceRefetch: true,
        })(dispatch, getState, undefined);
        await api.endpoints.getOrgUsers.initiate(state.global.selectedOrgId, {
          forceRefetch: true,
        })(dispatch, getState, undefined);
      },
    }),
    changeUserRole: builder.mutation<
      ChangeRoleResponse,
      { org_id: number; user_id: number; role: string }
    >({
      query: (payload) => ({
        url: `/org-admin/users/role/change`,
        method: "post",
        body: payload,
      }),
      transformResponse: (response: GenericAPIResponse<ChangeRoleResponse>) => {
        return response.data;
      },

      onQueryStarted: async (_arg, { dispatch, queryFulfilled, getState }) => {
        const { org_id } = _arg;

        await queryFulfilled;
        api.endpoints.getOrgUsers.initiate(org_id, {
          forceRefetch: true,
        })(dispatch, getState, undefined);
        api.endpoints.getInvitationCount.initiate(org_id, {
          forceRefetch: true,
        })(dispatch, getState, undefined);
        dispatch(setEditMode(false));
      },
    }),
    getPermissions: builder.query<GetPermissionsResponse, void>({
      query: () => ({
        url: `/role-permission`,
      }),
      transformResponse: (
        response: GenericAPIResponse<GetPermissionsResponse>
      ) => {
        return response.data;
      },
    }),
    getPresence: builder.query<Presence[], number>({
      query: (orgId) => ({
        url: `/org-admin/presence/org/${orgId}`,
      }),
      transformResponse: (response: GenericAPIResponse<Presence[]>) => {
        return response.data;
      },
    }),
    getTaskTypes: builder.query<TaskType[], void>({
      query: () => ({
        url: `/task/types`,
      }),
      transformResponse: (response: GenericAPIResponse<TaskType[]>) => {
        return response.data;
      },
    }),
    getCallHistory: builder.query<CallHistoryResponse, CallHistoryPayload>({
      query: (body) => {
        //Call History starts from page 0
        return {
          url: `/org-admin/call-history/search`,
          method: "post",
          body,
        };
      },
      transformResponse: (
        response: GenericAPIResponse<CallHistoryResponse>
      ) => {
        return response.data;
      },
    }),
    getCustomerCallHistory: builder.query<
      CallHistoryResponse,
      CustomerCallHistoryPayload
    >({
      query: (payload) => {
        const { customerId, ...rest } = payload;
        const query = convertToSearchParams(rest);
        return {
          url: `/org-admin/call-history/customer/${customerId}${query}`,
        };
      },
      transformResponse: (
        response: GenericAPIResponse<CallHistoryResponse>
      ) => {
        return response.data;
      },
    }),
    getCallRecording: builder.query<CallRecording, number>({
      query: (callRecordingId) => {
        return {
          url: `/call-history/recording/${callRecordingId}`,
        };
      },
      transformResponse: (response: GenericAPIResponse<CallRecording>) => {
        return response.data;
      },
    }),
    getCallerTune: builder.query<GetCallerTuneResponse, number>({
      query: (orgId) => ({
        url: `/org-admin/voice-message/org/${orgId}`,
      }),
      transformResponse: (
        response: GenericAPIResponse<GetCallerTuneResponse>
      ) => {
        return response.data;
      },
      onQueryStarted: async (_, { dispatch, getState, queryFulfilled }) => {
        const data = await queryFulfilled;
        const { data: callerTuneData } = data || {};
        const {
          audio_message: { language, gender },
          message,
        } = callerTuneData || {};
        dispatch(setLanguage(language));
        dispatch(setGender(gender));
        dispatch(setMessage(message));
      },
    }),
    previewCallerTune: builder.mutation<
      PreviewCallerTuneResponse,
      PreviewCallerTunePayload
    >({
      query: (payload) => {
        const { orgId, ...body } = payload;
        return {
          url: `/org-admin/voice-message/preview/org/${payload.orgId}`,

          method: "post",
          body,
        };
      },
      transformResponse: (
        response: GenericAPIResponse<PreviewCallerTuneResponse>
      ) => {
        return response.data;
      },
      onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
        await queryFulfilled;
        dispatch(toggleEnableSave(true));
      },
    }),
    setCallerTune: builder.mutation<
      SetCallerTuneResponse,
      SetCallerTunePayload
    >({
      query: (payload) => {
        const { orgId, ...body } = payload;
        return {
          url: `/org-admin/voice-message/org/${orgId}`,
          method: "post",
          body,
        };
      },
      transformResponse: (
        response: GenericAPIResponse<SetCallerTuneResponse>
      ) => {
        return response.data;
      },

      onQueryStarted: async (_args, { dispatch, getState, queryFulfilled }) => {
        const { orgId } = _args;
        await queryFulfilled;
        api.endpoints.getCallerTune.initiate(orgId, {
          forceRefetch: true,
        })(dispatch, getState, undefined);
        dispatch(setStep(3));
        dispatch(toggleCopyCallerTuneModal(false));
      },
    }),
    getCustomer: builder.query<Customer, number>({
      query: (customerId) => {
        return {
          url: `/org-admin/customer/${customerId}`,
        };
      },
      transformResponse: (response: GenericAPIResponse<Customer>) => {
        return response.data;
      },
    }),
    getCustomerNotes: builder.query<
      GetCustomerNotesResponse,
      { customerId: number; page: number; page_size: number }
    >({
      query: (payload) => {
        const { customerId, page, page_size } = payload;
        return {
          url: `/org-admin/customer/${customerId}/notes?page=${page}&page_size=${page_size}`,
        };
      },
      transformResponse: (
        response: GenericAPIResponse<GetCustomerNotesResponse>
      ) => {
        return {
          ...response.data,
          table_rows: transformToNotesRows(response?.data?.rows || []),
        };
      },
    }),
    getAllOrgsSettings: builder.query<OrgSettings[][], void>({
      async queryFn(_arg, _queryApi, _extraOptions) {
        const { dispatch, getState } = _queryApi;
        const orgsResponse = await api.endpoints.getOrgs.initiate()(
          dispatch,
          getState,
          undefined
        );
        const orgs = orgsResponse?.data?.map((org) => org.id) || [];
        const promises = orgs.map((orgId) =>
          api.endpoints.getOrgSettings.initiate(orgId, {
            forceRefetch: true,
          })(dispatch, getState, undefined)
        );
        const result = await Promise.allSettled(promises);
        const orgsSettings: OrgSettings[][] = [];
        result.map((response) => {
          const { status } = response;
          if (status === "fulfilled") {
            const s = response.value;
            orgsSettings.push(s.data as unknown as OrgSettings[]);
          }
          return null;
        });
        return { data: orgsSettings as OrgSettings[][] };
      },
    }),
    copyCallerTune: builder.query<
      SetCallerTuneResponse,
      { fromOrgId: number; toOrgId: number }
    >({
      async queryFn(_arg, _queryApi, _extraOptions) {
        const { fromOrgId, toOrgId } = _arg;
        const { dispatch, getState } = _queryApi;
        const getCallerTuneResponse =
          await api.endpoints.getCallerTune.initiate(fromOrgId)(
            dispatch,
            getState,
            undefined
          );
        const { data } = getCallerTuneResponse;
        const { message, audio_message } = data || {};
        const { gender, label, language } = audio_message || {};
        if (gender && label && language) {
          await api.endpoints.setCallerTune.initiate({
            orgId: toOrgId,
            gender,
            label,
            language,
            message: message || "",
          })(dispatch, getState, undefined);
          return {
            data: null as unknown as SetCallerTuneResponse,
          };
        }
        return {
          error: {
            status: 404,
            data: "Getting Caller Tune failed",
          } as FetchBaseQueryError,
        };
      },
    }),
    getTasks: builder.query<GetTasksResponse, GetTasksPayload>({
      query: (payload) => ({
        url: `/org-admin/task/search`,

        method: "post",
        body: payload,
      }),
      transformResponse: (response: GenericAPIResponse<GetTasksResponse>) => {
        return response.data;
      },
    }),
    getTasksCategories: builder.query<
      GetTasksCategoriesResponse,
      GetTasksCategoriesPayload
    >({
      async queryFn(_arg, _queryApi, _extraOptions) {
        const { dispatch, getState } = _queryApi;
        const responses = await Promise.all([
          api.endpoints.getTasks.initiate(_arg.freshLeadsPayload, {
            forceRefetch: false,
          })(dispatch, getState, undefined),
          api.endpoints.getTasks.initiate(_arg.followUpsPayload, {
            forceRefetch: false,
          })(dispatch, getState, undefined),
          api.endpoints.getTasks.initiate(_arg.remindersPayload, {
            forceRefetch: false,
          })(dispatch, getState, undefined),
        ]);
        const data: GetTasksCategoriesResponse = {
          freshLeadsResponse: responses?.[0]?.data || null,
          followUpsResponse: responses?.[1]?.data || null,
          remindersResponse: responses?.[2]?.data || null,
        };
        return { data };
      },
    }),
    getCustomers: builder.query<GetCustomersResponse, GetCustomersPayload>({
      query: (payload) => ({
        url: `/org-admin/customer/search`,
        method: "post",
        body: payload,
      }),
      transformResponse: (
        response: GenericAPIResponse<GetCustomersResponse>
      ) => {
        return response.data;
      },
    }),
    createTask: builder.mutation<any, CreateTaskPayload>({
      query: (payload) => ({
        url: "/org-admin/task",
        method: "post",
        body: payload,
      }),
      transformResponse: (response: GenericAPIResponse<any>) => {
        return response.data;
      },
    }),
    updateTask: builder.mutation<any, UpdateTaskPayload>({
      query: (payload) => {
        const { taskId, ...body } = payload;
        return {
          url: `/org-admin/task/${taskId}`,
          method: "put",
          body,
        };
      },
      transformResponse: (response: GenericAPIResponse<any>) => {
        return response.data;
      },
    }),
    deleteTask: builder.mutation<any, number>({
      query: (taskId) => ({
        url: `/task/${taskId}`,
        method: "delete",
      }),
      transformResponse: (response: GenericAPIResponse<any>) => {
        return response.data;
      },
    }),
    getCustomerFilters: builder.query<CustomerFilter[], void>({
      query: () => ({ url: "/org-admin/customer/filters" }),
      transformResponse: (response: GenericAPIResponse<CustomerFilter[]>) => {
        return response.data;
      },
    }),
    getTaskFilters: builder.query<TaskFilter[], void>({
      query: () => ({ url: "/org-admin/task/filters" }),
      transformResponse: (response: GenericAPIResponse<TaskFilter[]>) => {
        return response.data;
      },
    }),
    getCustomerInsights: builder.query<
      GetCustomerInsightsResponse,
      GetCustomerInsightsPayload
    >({
      query: (payload) => ({
        url: `/customer/insights`,

        method: "post",
        body: payload,
      }),
      transformResponse: (
        response: GenericAPIResponse<GetCustomerInsightsResponse>
      ) => {
        return response.data;
      },
    }),
    customerBulkUpdate: builder.mutation<any, CustomerBulkUpdatePayload>({
      query: (payload) => ({
        url: "/customer/bulk",
        method: "put",
        body: payload,
      }),
      transformResponse: (response: GenericAPIResponse<any>) => {
        return response.data;
      },
    }),
    updateContact: builder.mutation<any, UpdateContactPayload>({
      query: (payload) => {
        const { customer_id, source_type, ...rest } = payload;
        const sourceTypeOption = sourceTypeOptions.find(
          (e) => e.value === source_type
        );
        const body = {
          ...rest,
          ...(!source_type ? { source_type: null } : {}),
          ...(sourceTypeOption ? { source_type: sourceTypeOption.value } : {}),
        };
        return {
          url: `/customer/${customer_id}`,
          method: "put",
          body,
        };
      },
      transformResponse: (response: GenericAPIResponse<any>) => {
        return response.data;
      },
    }),
    getLeadStage: builder.query<LeadStage, number>({
      query: (leadStageId) => ({
        url: `/org-admin/lead-stage/${leadStageId}`,
      }),
      transformResponse: (response: GenericAPIResponse<LeadStage>) => {
        return response.data;
      },
    }),
    getOrgLeadStages: builder.query<LeadStage[], number>({
      query: (orgId) => ({
        url: `/org-admin/lead-stage/org/${orgId}`,
      }),
      transformResponse: (response: GenericAPIResponse<LeadStage[]>) => {
        return response.data;
      },
    }),
    createLeadStage: builder.mutation<any, CreateLeadStagePayload>({
      query: (body) => {
        return {
          url: "/org-admin/lead-stage",
          method: "post",
          body,
        };
      },
      transformResponse: (response: GenericAPIResponse<any>) => {
        return response.data;
      },
    }),
    updateLeadStage: builder.mutation<
      UpdateLeadStageResponse,
      UpdateLeadStagePayload
    >({
      query: (payload) => {
        const { id, ...body } = payload;
        return {
          url: `/org-admin/lead-stage/${id}`,
          method: "put",
          body,
        };
      },
      transformResponse: (
        response: GenericAPIResponse<UpdateLeadStageResponse>
      ) => response.data,
    }),
    deleteLeadStage: builder.mutation<any, number>({
      query: (leadStageId) => {
        return {
          url: `/org-admin/lead-stage/${leadStageId}`,
          method: "delete",
        };
      },
      transformResponse: (response: GenericAPIResponse<any>) => {
        return response.data;
      },
    }),
    getLeadGroup: builder.query<LeadGroup, number>({
      query: (leadGroupId) => ({
        url: `/org-admin/lead-group/${leadGroupId}`,
      }),
      transformResponse: (response: GenericAPIResponse<LeadGroup>) => {
        return response.data;
      },
    }),
    getOrgLeadGroups: builder.query<LeadGroup[], number>({
      query: (orgId) => ({
        url: `/org-admin/lead-group/org/${orgId}`,
      }),
      transformResponse: (response: GenericAPIResponse<LeadGroup[]>) => {
        return response.data;
      },
    }),
    createLeadGroup: builder.mutation<
      CreateLeadGroupResponse,
      CreateLeadGroupPayload
    >({
      query: (body) => {
        return {
          url: `/org-admin/lead-group`,
          method: "post",
          body,
        };
      },
      transformResponse: (
        response: GenericAPIResponse<CreateLeadGroupResponse>
      ) => response.data,
    }),
    updateLeadGroup: builder.mutation<
      UpdateLeadGroupResponse,
      UpdateLeadGroupPayload
    >({
      query: (payload) => {
        const { id, ...body } = payload;
        return {
          url: `/org-admin/lead-group/${id}`,
          method: "put",
          body,
        };
      },
      transformResponse: (
        response: GenericAPIResponse<UpdateLeadGroupResponse>
      ) => response.data,
    }),
    deleteLeadGroup: builder.mutation<any, number>({
      query: (leadGroupId) => {
        return {
          url: `/org-admin/lead-group/${leadGroupId}`,
          method: "delete",
        };
      },
      transformResponse: (response: GenericAPIResponse<any>) => {
        return response.data;
      },
    }),
    updateUser: builder.mutation<any, UpdateUserPayload>({
      query: (payload) => {
        const { userId, ...body } = payload;
        return {
          url: `/org-admin/users/${userId}`,
          method: "put",
          body,
        };
      },
      transformResponse: (response: GenericAPIResponse<any>) => {
        return response.data;
      },
      onQueryStarted: async (_, { queryFulfilled, getState, dispatch }) => {
        const state = getState() as RootState;
        try {
          await queryFulfilled;
          await api.endpoints.getMember.initiate(state.global.selectedStaff, {
            forceRefetch: true,
          })(dispatch, getState, undefined);
          await api.endpoints.getAllMembers.initiate(undefined, {
            forceRefetch: true,
          })(dispatch, getState, undefined);
          await api.endpoints.getOrgUsers.initiate(state.global.selectedOrgId, {
            forceRefetch: true,
          })(dispatch, getState, undefined);
        } catch (e) {}
      },
    }),
    searchNotes: builder.query<SearchNotesResponse, SearchNotesPayload>({
      query: (payload) => ({
        url: `/org-admin/note/search`,
        method: "post",
        body: payload,
      }),
      transformResponse: (
        response: GenericAPIResponse<SearchNotesResponse>
      ) => {
        return response.data;
      },
    }),
    getCategories: builder.query<Category[], void>({
      query: () => ({ url: "/categories" }),
      transformResponse: (response: GenericAPIResponse<Category[]>) =>
        response.data,
    }),
    getLeadStageTypes: builder.query<LeadStageType[], void>({
      query: () => ({
        url: `/lead-stage/types`,
      }),
      transformResponse: (response: GenericAPIResponse<LeadStageType[]>) =>
        response.data,
    }),
    changeLeadStagePosition: builder.mutation<
      ChangeLeadStagePositionResponse,
      ChangeLeadStagePositionPayload
    >({
      query: (payload) => {
        const { id, ...body } = payload;
        return {
          url: `/org-admin/lead-stage/${id}/change-position`,
          method: "put",
          body,
        };
      },
      transformResponse: (
        response: GenericAPIResponse<ChangeLeadStagePositionResponse>
      ) => response.data,
    }),
    getLeadGroupTypes: builder.query<LeadGroupType[], void>({
      query: () => ({
        url: `/lead-group/types`,
      }),
      transformResponse: (response: GenericAPIResponse<LeadGroupType[]>) =>
        response.data,
    }),
    customerImport: builder.mutation<
      CustomerImportResponse,
      CustomerImportPayload
    >({
      query: (body) => {
        return {
          url: `/org-admin/customer/import`,
          body,
          method: "post",
        };
      },
      transformResponse: (
        response: GenericAPIResponse<CustomerImportResponse>
      ) => response.data,
    }),
    faceBookLogin: builder.mutation<any, any>({
      query: ({ org_id, authResponse, appId, config_id }: any) => ({
        url: "/dragonfly/marketing/business-login",
        method: "post",
        body: {
          org_id,
          authResponse,
          appId,
          config_id,
        },
      }),
    }),
    faceBookES: builder.mutation<any, any>({
      query: ({ org_id, authResponse, appId, config_id }: any) => ({
        url: "/dragonfly/whatsapp/embedded-signup",
        method: "post",
        body: {
          org_id,
          authResponse,
          appId,
          config_id,
        },
      }),
    }),
    heltarAccount: builder.mutation<any, any>({
      query: ({ org_id }: any) => ({
        url: "/whatsapp/heltar/embedded-signup",
        method: "post",
        body: {
          org_id,
        },
      }),
    }),
    dragonflyAccount: builder.query<DragonflyAccount[], void>({
      query: () => ({
        url: `/dragonfly/whatsapp/org-admin`,
      }),
      transformResponse: (response: GenericAPIResponse<DragonflyAccount[]>) => {
        return response.data;
      },
    }),
    getFaceBookPages: builder.query<FacebookPage[], void>({
      query: () => ({
        url: "/dragonfly/marketing/available-pages",
        method: "get",
      }),
      transformResponse: (response: GenericAPIResponse<FacebookPage[]>) =>
        response.data,
    }),
    linkFaceBookPage: builder.mutation<any, LinkFacebookPagePayload>({
      query: (body) => ({
        url: "/dragonfly/marketing/page",
        method: "post",
        body,
      }),
    }),
    deleteContact: builder.mutation<any, number>({
      query: (customer_id) => ({
        url: `/org-admin/customer/${customer_id}`,
        method: "delete",
      }),
      transformResponse: (response: GenericAPIResponse<any>) => response.data,
    }),
    createContact: builder.mutation<
      CreateContactResponse,
      CreateContactPayload
    >({
      query: (body) => {
        return {
          url: "/org-admin/customer",
          method: "post",
          body,
        };
      },
      transformResponse: (
        response: GenericAPIResponse<CreateContactResponse>
      ) => response.data,
    }),
    getProducts: builder.query<any, GetProductsPayload>({
      query: (payload) => ({
        url: `/org-admin/product/org/${payload.id}?page=${payload.page}&page_size=${payload.page_size}`,
      }),
      transformResponse: (response: GenericAPIResponse<any>) => response.data,
    }),
    createProduct: builder.mutation<any, CreateProductPayload>({
      query: (body) => {
        return {
          url: "/org-admin/product",
          method: "post",
          body,
        };
      },
      transformResponse: (response: GenericAPIResponse<any>) => response.data,
    }),
    updateProduct: builder.mutation<any, UpdateProductPayload>({
      query: (payload) => {
        const { product_id, ...body } = payload;
        return {
          url: `/org-admin/product/1`,
          method: "post",
          body,
        };
      },
      transformResponse: (response: GenericAPIResponse<any>) => response.data,
    }),
    getProduct: builder.query<any, number>({
      query: (product_id) => ({
        url: `/org-admin/product/${product_id}`,
      }),
      transformResponse: (response: GenericAPIResponse<any>) => response.data,
    }),
    deleteProduct: builder.mutation<any, number>({
      query: (product_id) => ({
        url: `/org-admin/product/${product_id}`,
        method: "delete",
      }),
      transformResponse: (response: GenericAPIResponse<any>) => response.data,
    }),
    assignProduct: builder.mutation<any, AssignProductPayload>({
      query: (payload) => {
        const { customer_id, ...body } = payload;
        return {
          url: `/org-admin/customer/${customer_id}/assign-product`,
          method: "post",
          body,
        };
      },
      transformResponse: (response: GenericAPIResponse<any>) => response.data,
    }),
    unassignProduct: builder.mutation<any, UnassignProductPayload>({
      query: (payload) => {
        const { customer_id, ...body } = payload;
        return {
          url: `/org-admin/customer/${customer_id}/unassign-product`,
          method: "post",
          body,
        };
      },
      transformResponse: (response: GenericAPIResponse<any>) => response.data,
    }),
    getTask: builder.query<TaskData, number>({
      query: (task_id) => ({
        url: `/org-admin/task/${task_id}`,
      }),
      transformResponse: (response: GenericAPIResponse<TaskData>) =>
        response.data,
    }),
    getCustomerActivities: builder.query<CustomerActivity[], number>({
      query: (customer_id) => ({
        url: `/org-admin/customer/${customer_id}/activity`,
      }),
      transformResponse: (response: GenericAPIResponse<CustomerActivity[]>) =>
        response.data,
    }),
    getNote: builder.query<any, number>({
      query: (note_id) => ({ url: `/org-admin/note/${note_id}` }),
      transformResponse: (response: GenericAPIResponse<any>) => response.data,
    }),
    createNote: builder.mutation<any, CreateNotePayload>({
      query: (body) => ({ url: `/org-admin/note`, method: "post", body }),
      transformResponse: (response: GenericAPIResponse<any>) => response.data,
    }),
    updateNote: builder.mutation<any, UpdateNotePayload>({
      query: (payload) => {
        const { note_id, ...body } = payload;
        return {
          url: `/org-admin/note/${note_id}`,
          method: "put",
          body,
        };
      },
      transformResponse: (response: GenericAPIResponse<any>) => response.data,
    }),
    deleteNote: builder.mutation<any, number>({
      query: (note_id) => ({
        url: `/org-admin/note/${note_id}`,
        method: "delete",
      }),
      transformResponse: (response: GenericAPIResponse<any>) => response.data,
    }),
    getOnboardingUIConfig: builder.query<
      GetOnboardUIConfigResponse,
      string | void
    >({
      query: (invite_code) => ({
        url: `/org-admin/org/onboarding-ui-config${
          invite_code ? `?invite_code=${invite_code}` : ""
        }`,
      }),
      transformResponse: (
        response: GenericAPIResponse<GetOnboardUIConfigResponse>
      ) => response.data,
    }),
    onboardOrg: builder.mutation<OnboardOrgResponse, OnboardOrgPayload>({
      query: (body) => {
        return {
          url: "/org-admin/org/onboard",
          method: "post",
          body,
        };
      },
      transformResponse: (response: GenericAPIResponse<OnboardOrgResponse>) =>
        response.data,
    }),
    acceptTNC: builder.mutation<any, void>({
      query: (body) => ({
        url: "/users/accept-tnc",
        method: "put",
        body,
      }),
      transformResponse: (response: GenericAPIResponse<any>) => response.data,
    }),
    startTrial: builder.mutation<any, StartTrialPayload>({
      query: (body) => ({
        url: "/org-admin/orders/start-trial",
        method: "post",
        body,
      }),
      transformResponse: (response: GenericAPIResponse<any>) => response.data,
    }),
    bookDemo: builder.mutation<any, void>({
      query: () => ({
        url: "/users/book-a-demo",
        method: "post",
      }),
      transformResponse: (response: GenericAPIResponse<any>) => response.data,
    }),
    getUserRecentDevice: builder.query<GetUserRecentDeviceResponse, number>({
      query: (userId) => ({
        url: `/users/${userId}/recent-device`,
      }),
      transformResponse: (
        response: GenericAPIResponse<GetUserRecentDeviceResponse>
      ) => response.data,
    }),
    clickToCall: builder.mutation<ClickToCallResponse, ClickToCallPayload>({
      query: (payload) => ({
        url: `/customer/click-2-call?customer_number=${encodeURIComponent(
          payload.customer_number
        )}&user_number=${encodeURIComponent(payload.user_number)}&call_action=${
          payload.call_action
        }`,
      }),
      transformResponse: (response: GenericAPIResponse<ClickToCallResponse>) =>
        response.data,
    }),
    getOrgIntegrationSettings: builder.query<
      OrgIntegrationSettings,
      OrgIntegrationSettingsPayload
    >({
      query: (payload) => ({
        url: `/org-admin/integration/setting/org/${payload.org_id}?page=${payload.page}&page_size=${payload.page_size}`,
      }),
      transformResponse: (
        response: GenericAPIResponse<OrgIntegrationSettings>
      ) => response.data,
    }),
    getAllIntegrationSettings: builder.query<
      IntegrationSetting[],
      Pick<OrgIntegrationSettingsPayload, "page" | "page_size">
    >({
      async queryFn(_arg, _queryApi, _extraOptions) {
        const { dispatch, getState } = _queryApi;
        const orgsResponse = await api.endpoints.getOrgs.initiate(undefined, {
          forceRefetch: false,
        })(dispatch, getState, undefined);
        const orgIds = orgsResponse?.data?.map((o) => o.id) || [];
        const promises = orgIds.map((orgId) =>
          api.endpoints.getOrgIntegrationSettings.initiate(
            {
              org_id: orgId,
              page: _arg.page,
              page_size: _arg.page_size,
            },
            {
              forceRefetch: true,
            }
          )(dispatch, getState, undefined)
        );
        const result = await Promise.allSettled(promises);
        const integrationSettings: IntegrationSetting[][] = [];
        result.map((response) => {
          const { status } = response;
          if (status === "fulfilled") {
            const s = response.value;
            if (s?.data?.rows?.length) {
              integrationSettings.push(s.data.rows);
            }
          }
          return null;
        });
        return { data: integrationSettings.flat() as IntegrationSetting[] };
      },
    }),
    createOrgIntegration: builder.mutation<
      CreateOrgIntegrationResponse,
      CreateOrgIntegrationPayload
    >({
      query: (payload) => ({
        url: `/org-admin/integration`,
        method: "post",
        body: payload,
      }),
      transformResponse: (
        response: GenericAPIResponse<CreateOrgIntegrationResponse>
      ) => response.data,
    }),
    updateOrgIntegration: builder.mutation<
      IntegrationSetting,
      UpdateOrgIntegrationPayload
    >({
      query: (payload) => {
        const { id, ...body } = payload;
        return {
          url: `/org-admin/integration/setting/${id}`,
          method: "put",
          body: body,
        };
      },
      transformResponse: (response: GenericAPIResponse<IntegrationSetting>) =>
        response.data,
    }),
    deleteOrgIntegration: builder.mutation<any, number>({
      query: (payload) => ({
        url: `/org-admin/integration/setting/${payload}`,
        method: "delete",
      }),
      transformResponse: (response: GenericAPIResponse<any>) => response.data,
    }),
    getPublicURL: builder.query<GetPublicURLResponse, GetPublicURLPayload>({
      query: (payload) => ({
        url: `/s3/getObject?file_name=${payload.filename}${
          "no_redirect" in payload ? `&no_redirect=${payload.no_redirect}` : ""
        }`,
      }),
      transformResponse: (response: GenericAPIResponse<GetPublicURLResponse>) =>
        response.data,
    }),
    getFileDownload: builder.mutation<
      Blob,
      { url: string; no_redirect: string }
    >({
      query: ({ url, no_redirect }) => ({
        url: url,
        method: "GET",
        params: { no_redirect },
        responseType: "blob", // Ensure binary data response for files
        mode: "cors",
        credentials: "same-origin",
      }),
    }),
    getTaskInsights: builder.query<TaskInsights, GetTaskInsightsPayload>({
      query: (payload) => ({
        url: "/task/insights",
        method: "post",
        body: payload,
      }),
      transformResponse: (response: GenericAPIResponse<TaskInsights>) => {
        return response.data;
      },
    }),
    getEmailIntegrations: builder.query<EmailIntegration[], void>({
      query: () => ({
        url: "/email-integration",
      }),
      transformResponse: (response: GenericAPIResponse<EmailIntegration[]>) => {
        return response.data;
      },
    }),
    getEmailIntegration: builder.query<any, string>({
      query: (id) => ({
        url: `/email-integration/${id}`,
      }),
      transformResponse: (response: GenericAPIResponse<any>) => {
        return response.data;
      },
    }),
    createEmailIntegration: builder.mutation<
      CreateEmailIntegrationResponse,
      CreateEmailIntegrationPayload
    >({
      query: (body) => ({
        url: `/email-integration`,
        method: "post",
        body,
      }),
      transformResponse: (
        response: GenericAPIResponse<CreateEmailIntegrationResponse>
      ) => {
        return response.data;
      },
    }),
    updateEmailIntegration: builder.mutation<
      any,
      UpdateEmailIntegrationPayload
    >({
      query: (payload) => {
        const { id, ...body } = payload;
        return {
          url: `/email-integration/${id}`,
          method: "put",
          body,
        };
      },
      transformResponse: (response: GenericAPIResponse<any>) => {
        return response.data;
      },
    }),
    getLastEmail: builder.query<GetLastEmailResponse, string>({
      query: (id) => ({
        url: `/email-integration/${id}/last-email`,
      }),
      transformResponse: (
        response: GenericAPIResponse<GetLastEmailResponse>
      ) => {
        return response.data;
      },
    }),
    getEmails: builder.query<Email[], string>({
      query: (id) => ({
        url: `/email-integration/${id}/emails`,
      }),
      transformResponse: (response: GenericAPIResponse<Email[]>) => {
        return response.data;
      },
    }),
    getAvailablePlans: builder.query<AvailablePlansResponse, void>({
      query: () => ({ url: "/plans/available" }),
      transformResponse: (
        response: GenericAPIResponse<AvailablePlansResponse>
      ) => response.data,
    }),
    getNumberDetails: builder.query<GetNumberDetailsResponse, string>({
      query: (void_number) => ({
        url: `/numbers/details/${void_number}`,
      }),
      transformResponse: (
        response: GenericAPIResponse<GetNumberDetailsResponse>
      ) => response.data,
    }),
    searchAvailableNumbers: builder.query<
      SearchAvailableNumbersResponse,
      SearchAvailableNumbersPayload
    >({
      query: (payload) => ({
        url: `/numbers/available?page=${payload.page}&page_size=${
          payload.page_size
        }${payload.search ? `&search=${payload.search}` : ""}`,
      }),
      transformResponse: (response: GenericAPIResponse<any>) => response.data,
    }),
    createTopupOrder: builder.mutation<
      CreateTopupOrderResponse,
      CreateTopupOrderPayload
    >({
      query: (body) => ({
        url: "/orders/v2/topup",
        method: "post",
        body,
      }),
      transformResponse: (
        response: GenericAPIResponse<CreateTopupOrderResponse>
      ) => response.data,
    }),
    attemptPayment: builder.mutation<
      any,
      {
        order_id: number;
      }
    >({
      query: (body) => {
        return {
          url: "/orders/payment-attempt",
          method: "post",
          body,
        };
      },
      transformResponse: (response: GenericAPIResponse<any>) => response.data,
    }),
    getOrder: builder.query<GetOrderResponse, number>({
      query: (order_id) => ({
        url: `/orders/${order_id}`,
      }),
      transformResponse: (response: GenericAPIResponse<GetOrderResponse>) =>
        response.data,
    }),
    getGSTBusinessName: builder.query<string, string>({
      query: (gstin) => ({
        url: `/org/kyc/bv/gst/${gstin}`,
      }),
      transformResponse: (response: GenericAPIResponse<string>) =>
        response.data,
    }),
    updateOrder: builder.mutation<any, { order_id: number; gstin: string }>({
      query: (payload) => {
        const { order_id, ...body } = payload;
        return {
          url: `/orders/v2/${order_id}`,
          method: "put",
          body,
        };
      },
      transformResponse: (response: GenericAPIResponse<any>) => response.data,
    }),
    reserveNumber: builder.mutation<any, NumbersReservePayload>({
      query: (body) => ({
        url: "/numbers/reserve",
        method: "post",
        body,
      }),
      transformResponse: (response: GenericAPIResponse<any>) => response.data,
    }),
    createPurchaseOrder: builder.mutation<
      CreatePurchaseOrderResponse,
      CreatePurchaseOrderPayload
    >({
      query: (body) => ({
        url: "/orders/v2/buy-now",
        method: "post",
        body,
      }),
      transformResponse: (
        response: GenericAPIResponse<CreatePurchaseOrderResponse>
      ) => response.data,
    }),
    createRenewOrder: builder.mutation<any, CreateRenewOrderPayload>({
      query: (body) => ({
        url: "/orders/v2/renew",
        method: "post",
        body,
      }),
      transformResponse: (response: GenericAPIResponse<any>) => response.data,
    }),
    createUpgradeOrder: builder.mutation<any, CreateRenewOrderPayload>({
      query: (body) => ({
        url: "/orders/v2/upgrade",
        method: "post",
        body,
      }),
      transformResponse: (response: GenericAPIResponse<any>) => response.data,
    }),
    getSubscriptionPlan: builder.query<GetSubscriptionPlanResponse, void>({
      query: () => ({
        url: "/subscription/plan",
      }),
      transformResponse: (
        response: GenericAPIResponse<GetSubscriptionPlanResponse>
      ) => response.data,
    }),
    getLeads: builder.query<GetCustomersResponse, GetCustomersPayload>({
      query: (payload) => ({
        url: `/customer/clear-storage/search`,
        method: "post",
        body: payload,
      }),
      transformResponse: (response: GenericAPIResponse<GetCustomersResponse>) =>
        response.data,
    }),
    getLeadsCount: builder.query<{ count: number }, number>({
      query: (orgId) => ({
        url: `/org-admin/customer/count?org_id=${orgId}`,
      }),
      transformResponse: (response: GenericAPIResponse<any>) => response.data,
    }),
    getAllLeadsCount: builder.query<LeadsCount[], void>({
      async queryFn(_arg, _queryApi, _extraOptions) {
        const { dispatch, getState } = _queryApi;
        const orgsResponse = await api.endpoints.getOrgs.initiate(undefined, {
          forceRefetch: false,
        })(dispatch, getState, undefined);
        const orgIds = orgsResponse?.data?.map((o) => o.id) || [];
        const promises = orgIds.map((orgId) =>
          api.endpoints.getLeadsCount.initiate(orgId, {
            forceRefetch: true,
          })(dispatch, getState, undefined)
        );
        const responses: LeadsCount[] = [];
        const result = await Promise.allSettled(promises);
        result?.map((response) => {
          const { status } = response;
          if (status === "fulfilled" && response?.value?.data) {
            responses.push({
              org_id: response?.value?.originalArgs || 0,
              count: response.value.data?.count || 0,
            });
          }
          return null;
        });

        return { data: responses };
      },
    }),
    deleteLeads: builder.mutation<any, { customer_ids: number[] }>({
      query: (body) => ({
        url: "/customer/clear-storage",
        method: "post",
        body,
      }),
      transformResponse: (response: GenericAPIResponse<any>) => response.data,
    }),
    getEmail: builder.query<any, string>({
      query: (id) => ({
        url: `/emails/${id}`,
      }),
      transformResponse: (response: GenericAPIResponse<any>) => response.data,
    }),
    getFacebookPage: builder.query<any, number>({
      query: (id) => ({
        url: `/dragonfly/marketing/page/${id}`,
      }),
      transformResponse: (response: GenericAPIResponse<any>) => response.data,
    }),
    getFacebookForms: builder.query<FacebookForm[], string>({
      query: (id) => ({
        url: `/dragonfly/marketing/page/${id}/forms`,
      }),
      transformResponse: (response: GenericAPIResponse<FacebookForm[]>) =>
        response.data,
    }),
    getFacebookForm: builder.query<
      FacebookFormData,
      { form_id: string; page_id: string }
    >({
      query: (payload) => ({
        url: `/dragonfly/marketing/page/${payload.page_id}/forms/${payload.form_id}`,
      }),
      transformResponse: (response: GenericAPIResponse<FacebookFormData>) =>
        response.data,
    }),
    getAllFacebookForms: builder.query<
      ({ page_id: string } & FacebookForm)[],
      string[]
    >({
      async queryFn(_arg, _queryApi, _extraOptions) {
        const { dispatch, getState } = _queryApi;
        const promises = _arg.map((pageId) =>
          api.endpoints.getFacebookForms.initiate(pageId, {
            forceRefetch: true,
          })(dispatch, getState, undefined)
        );
        const result = await Promise.allSettled(promises);
        const forms: ({ page_id: string } & FacebookForm)[][] = [];
        result.map((response) => {
          const { status } = response;
          if (status === "fulfilled") {
            const s = response.value;
            forms.push(
              (s?.data || []).map((form) => {
                return {
                  ...form,
                  page_id: String(response?.value?.originalArgs),
                };
              }) as ({ page_id: string } & FacebookForm)[]
            );
          }
          return null;
        });
        return { data: forms.flat() as ({ page_id: string } & FacebookForm)[] };
      },
    }),
    getMarketingIntegrations: builder.query<MarketingIntegration[], void>({
      query: () => ({
        url: `/dragonfly/marketing/integration`,
      }),
      transformResponse: (
        response: GenericAPIResponse<MarketingIntegration[]>
      ) => response.data,
    }),
    createMarketingIntegration: builder.mutation<
      CreateMarketingIntegrationResponse,
      CreateMarketingIntegrationPayload
    >({
      query: (body) => ({
        url: `/dragonfly/marketing/integration`,
        method: "post",
        body,
      }),
      transformResponse: (
        response: GenericAPIResponse<CreateMarketingIntegrationResponse>
      ) => response.data,
    }),
    updateMarketingIntegration: builder.mutation<
      UpdateMarketingIntegrationResponse,
      UpdateMarketingIntegrationPayload
    >({
      query: (payload) => {
        const { id, ...body } = payload;
        return {
          url: `/dragonfly/marketing/integration/${id}`,
          method: "put",
          body,
        };
      },
      transformResponse: (
        response: GenericAPIResponse<CreateMarketingIntegrationResponse>
      ) => response.data,
    }),
    getFacebookAccount: builder.query<FacebookAccount, void>({
      query: () => ({
        url: `/dragonfly/marketing/account-details`,
      }),
      transformResponse: (response: GenericAPIResponse<FacebookAccount>) =>
        response.data,
    }),
    getAllLeadStages: builder.query<
      { allLeadStages: LeadStage[]; leadStages: LeadStage[][] },
      void
    >({
      async queryFn(_arg, _queryApi, _extraOptions) {
        const { dispatch, getState } = _queryApi;
        const orgsResponse = await api.endpoints.getOrgs.initiate(undefined, {
          forceRefetch: false,
        })(dispatch, getState, undefined);
        const orgIds = orgsResponse?.data?.map((o) => o.id) || [];
        const promises = orgIds.map((orgId) =>
          api.endpoints.getOrgLeadStages.initiate(orgId, {
            forceRefetch: true,
          })(dispatch, getState, undefined)
        );
        const result = await Promise.allSettled(promises);
        const leadStages: LeadStage[][] = [];
        result.map((response) => {
          const { status } = response;
          if (status === "fulfilled") {
            const s = response.value;
            if (s?.data?.length) {
              leadStages.push(s.data);
            }
          }
          return null;
        });
        return {
          data: { allLeadStages: leadStages.flat() as LeadStage[], leadStages },
        };
      },
    }),
    getAllLeadGroups: builder.query<LeadGroup[], void>({
      async queryFn(_arg, _queryApi, _extraOptions) {
        const { dispatch, getState } = _queryApi;
        const orgsResponse = await api.endpoints.getOrgs.initiate(undefined, {
          forceRefetch: false,
        })(dispatch, getState, undefined);
        const orgIds = orgsResponse?.data?.map((o) => o.id) || [];
        const promises = orgIds.map((orgId) =>
          api.endpoints.getOrgLeadGroups.initiate(orgId, {
            forceRefetch: true,
          })(dispatch, getState, undefined)
        );
        const result = await Promise.allSettled(promises);
        const leadGroups: LeadGroup[][] = [];
        result.map((response) => {
          const { status } = response;
          if (status === "fulfilled") {
            const s = response.value;
            if (s?.data?.length) {
              leadGroups.push(s.data);
            }
          }
          return null;
        });
        return { data: leadGroups.flat() as LeadGroup[] };
      },
    }),
    getAllLabels: builder.query<OrgLabel[], void>({
      async queryFn(_arg, _queryApi, _extraOptions) {
        const { dispatch, getState } = _queryApi;
        const orgsResponse = await api.endpoints.getOrgs.initiate(undefined, {
          forceRefetch: false,
        })(dispatch, getState, undefined);
        const orgIds = orgsResponse?.data?.map((o) => o.id) || [];
        const promises = orgIds.map((orgId) =>
          api.endpoints.getOrgLabels.initiate(
            { orgId },
            {
              forceRefetch: true,
            }
          )(dispatch, getState, undefined)
        );
        const result = await Promise.allSettled(promises);
        const labels: OrgLabel[][] = [];
        result.map((response) => {
          const { status } = response;
          if (status === "fulfilled") {
            const s = response.value;
            if (s?.data?.length) {
              labels.push(s.data);
            }
          }
          return null;
        });
        return { data: labels.flat() as OrgLabel[] };
      },
    }),
    getIndiaMartLogs: builder.query<IndiaMartLog[], string>({
      query: (id) => ({
        url: `/email-integration/${id}/runs`,
      }),
      transformResponse: (response: GenericAPIResponse<IndiaMartLog[]>) =>
        response.data,
    }),
    getFacebookLogs: builder.query<FacebookLog[], string>({
      query: (id) => ({
        url: `/dragonfly/marketing/integration/${id}/runs`,
      }),
      transformResponse: (response: GenericAPIResponse<FacebookLog[]>) =>
        response.data,
    }),
    getActionSettings: builder.query<ActionSetting[], number>({
      query: (orgId) => ({
        url: `/org-admin/event/action-setting/org/${orgId}`,
      }),
      transformResponse: (response: GenericAPIResponse<ActionSetting[]>) =>
        response.data,
    }),
    createActionSettings: builder.mutation<
      any,
      Pick<
        ActionSetting,
        | "name"
        | "description"
        | "org_id"
        | "event_name"
        | "action_type"
        | "action_config"
        | "action_filter"
      >
    >({
      query: (body) => ({
        url: `/org-admin/event/action-setting`,
        method: "post",
        body,
      }),
      transformResponse: (response: GenericAPIResponse<any>) => response.data,
    }),
    updateActionSettings: builder.mutation<
      any,
      Pick<
        Partial<ActionSetting>,
        | "id"
        | "name"
        | "description"
        | "status"
        | "event_name"
        | "action_type"
        | "action_config"
        | "action_filter"
      >
    >({
      query: (payload) => {
        const { id, ...body } = payload;
        return {
          url: `/org-admin/event/action-setting/${id}`,
          method: "put",
          body,
        };
      },
      transformResponse: (response: GenericAPIResponse<any>) => response.data,
    }),
    getAutomationLogs: builder.query<AutomationLog[], GetAutomationLogsPayload>(
      {
        query: (body) => ({
          url: `/org-admin/event/trigger-log/search`,
          method: "post",
          body,
        }),
        transformResponse: (response: GenericAPIResponse<AutomationLog[]>) =>
          response.data,
      }
    ),
    getWhatsappTemplateVariables: builder.query<any, number>({
      query: (orgId) => ({
        url: `/org-admin/template-variables/org/${orgId}`,
      }),
      transformResponse: (response: GenericAPIResponse<any>) => response.data,
    }),
    getAllWhatsappTemplateVariables: builder.query<
      {
        allVariables: (TemplateVariable & { org_id: number })[];
        orgVariablesArray: (TemplateVariable & { org_id: number })[][];
      },
      void
    >({
      async queryFn(_arg, _queryApi, _extraOptions) {
        const { dispatch, getState } = _queryApi;
        const orgsResponse = await api.endpoints.getOrgs.initiate(undefined, {
          forceRefetch: false,
        })(dispatch, getState, undefined);
        const orgIds = orgsResponse?.data?.map((o) => o.id) || [];
        const promises = orgIds.map((orgId) =>
          api.endpoints.getWhatsappTemplateVariables.initiate(orgId, {
            forceRefetch: true,
          })(dispatch, getState, undefined)
        );
        const result = await Promise.allSettled(promises);
        const orgVariablesArray: (TemplateVariable & { org_id: number })[][] =
          [];
        result.map((response) => {
          const { status } = response;
          if (status === "fulfilled") {
            const s = response.value;
            if (s?.data?.length) {
              const org_id = response?.value?.originalArgs || 0;
              orgVariablesArray.push(
                s.data?.map((v: TemplateVariable) => ({ ...v, org_id }))
              );
            }
          }
          return null;
        });
        return {
          data: {
            allVariables: orgVariablesArray.flat() as (TemplateVariable & {
              org_id: number;
            })[],
            orgVariablesArray: orgVariablesArray as (TemplateVariable & {
              org_id: number;
            })[][],
          },
        };
      },
    }),
    getInternalLeadStatus: builder.query<InternalLeadStatus, void>({
      query: () => ({
        url: `/internal-lead/status`,
      }),
      transformResponse: (response: GenericAPIResponse<InternalLeadStatus>) => {
        return response.data;
      },
    }),
    createDailyTopupOrder: builder.mutation<any, CreateDailyTopupOrderPayload>({
      query: (body) => ({
        url: "/orders/v2/daily-topup",
        method: "post",
        body,
      }),
      transformResponse: (response: GenericAPIResponse<any>) => response.data,
    }),
    getTalkTimeDetails: builder.query<TalktimeDetails, number>({
      query: (orgId) => ({
        url: `/org-admin/subscription/org/${orgId}/talk-time`,
      }),
      transformResponse: (response: GenericAPIResponse<TalktimeDetails>) =>
        response.data,
    }),
    getAllTalkTimeDetails: builder.query<
      (TalktimeDetails & {
        org_id: number;
        isDailyTalktime: boolean;
        availableMinutes: number;
      })[],
      void
    >({
      async queryFn(_arg, _queryApi, _extraOptions) {
        const { dispatch, getState } = _queryApi;
        const orgsResponse = await api.endpoints.getOrgs.initiate(undefined, {
          forceRefetch: false,
        })(dispatch, getState, undefined);
        const orgIds = orgsResponse?.data?.map((o) => o.id) || [];
        const promises = orgIds.map((orgId) =>
          api.endpoints.getTalkTimeDetails.initiate(orgId, {
            forceRefetch: true,
          })(dispatch, getState, undefined)
        );
        const result = await Promise.allSettled(promises);
        const talktimes: (TalktimeDetails & {
          org_id: number;
          isDailyTalktime: boolean;
          availableMinutes: number;
        })[] = [];
        result.map((response) => {
          const { status } = response;
          if (status === "fulfilled") {
            const s = response.value;
            if (s?.data) {
              const consumedMinutes = Number(
                s?.data?.talk_time_consumed_today || "0"
              );
              const absoluteConsumedMinutes =
                consumedMinutes <= 0 ? 0 : consumedMinutes;
              talktimes.push({
                org_id: response?.value?.originalArgs || 0,
                ...s.data,
                isDailyTalktime: s.data.hasOwnProperty(
                  "talk_time_consumed_today"
                ),
                availableMinutes:
                  (s?.data?.total_talk_time || 0) - absoluteConsumedMinutes,
              });
            }
          }
          return null;
        });
        return {
          data: talktimes,
        };
      },
    }),
    getCRMSettingsForOrg: builder.query<GetCRMSettingsReponse, number>({
      query: (orgId) => ({
        url: `/org-admin/crm-setting/org/${orgId}`,
      }),
      transformResponse: (
        response: GenericAPIResponse<GetCRMSettingsReponse>
      ) => response.data,
    }),
    getCRMSettingsForAllOrgs: builder.query<GetCRMSettingsReponse[], void>({
      async queryFn(_arg, _queryApi, _extraOptions) {
        const { dispatch, getState } = _queryApi;
        const orgsResponse = await api.endpoints.getOrgs.initiate(undefined, {
          forceRefetch: false,
        })(dispatch, getState, undefined);
        const orgIds = orgsResponse?.data?.map((o) => o.id) || [];
        const promises = orgIds.map((orgId) =>
          api.endpoints.getCRMSettingsForOrg.initiate(orgId, {
            forceRefetch: true,
          })(dispatch, getState, undefined)
        );
        const result = await Promise.allSettled(promises);
        const crmSettings: GetCRMSettingsReponse[] = [];
        result.map((response) => {
          const { status } = response;
          if (status === "fulfilled") {
            const s = response.value;
            if (s?.data) {
              crmSettings.push(s.data);
            }
          }
          return null;
        });
        return {
          data: crmSettings,
        };
      },
    }),

    customerImports: builder.mutation<
      ImportCustomerResponse,
      ImportCustomerPayload
    >({
      query: (body) => ({
        url: `/org-admin/customer/v2/import`,
        method: "post",
        body,
      }),
      transformResponse: (
        response: GenericAPIResponse<ImportCustomerResponse>
      ) => {
        return response.data;
      },
    }),

    importRecentContacts: builder.query<Job[], number>({
      query: (org_id) => ({
        url: `/org-admin/customer/v2/import-contact-recent-job/${org_id}`,
      }),
      transformResponse: (response: GenericAPIResponse<Job[]>) => {
        return response.data;
      },
    }),

    // Get Custom Fields
    getCustomFields: builder.query<CustomField[], number>({
      query: (org_id) => ({
        url: `/org-admin/customer-custom-field-label/${org_id}`,
      }),
      transformResponse: (response: GenericAPIResponse<CustomField[]>) => {
        return response.data;
      },
    }),

    // Create - POST for custom fields
    createCustomFields: builder.mutation<
      CreateCustomFieldResponse,
      CreateCustomFieldPayload
    >({
      query: (body) => ({
        url: `/org-admin/customer-custom-field-label`,
        method: "post",
        body,
      }),
      transformResponse: (
        response: GenericAPIResponse<CreateCustomFieldResponse>
      ) => {
        return response.data;
      },
    }),

    // Update - PUT for custom fields
    updateCustomFields: builder.mutation<
      UpdateCustomFieldResponse,
      UpdateCustomFieldPayload
    >({
      query: (payload) => {
        const { id, ...body } = payload;
        return {
          url: `org-admin/customer-custom-field-label/${id}`,
          method: "put",
          body,
        };
      },
      transformResponse: (
        response: GenericAPIResponse<UpdateCustomFieldResponse>
      ) => {
        return response.data;
      },
    }),

    // Delete - DELETE for custom fields
    deleteCustomFields: builder.mutation<DeleteCustomFieldResponse, number>({
      query: (id) => ({
        url: `/org-admin/customer-custom-field-label/${id}`,
        method: "delete",
      }),
      transformResponse: (
        response: GenericAPIResponse<DeleteCustomFieldResponse>
      ) => {
        return response.data;
      },
    }),

    // allowed fields for custom fields

    getAllowedFields: builder.query<AllowedCustomField[], void>({
      query: () => ({
        url: `/org-admin/customer-custom-field-label/allowed-fields`,
      }),
      transformResponse: (
        response: GenericAPIResponse<AllowedCustomField[]>
      ) => {
        return response.data;
      },
    }),

    getImportantFields: builder.query<CustomerCustomFieldResponse[], number>({
      query: (org_id) => ({
        url: `/org-admin/customer-important-field-label/${org_id}`,
      }),
      transformResponse: (
        response: GenericAPIResponse<CustomerCustomFieldResponse[]>
      ) => {
        return response.data;
      },
    }),

    // Create - POST for important fields
    createImportantFields: builder.mutation<
      CreateImportantCustomFieldResponse,
      CreateImportantCustomFieldPayload
    >({
      query: (body) => ({
        url: `/org-admin/customer-important-field-label`,
        method: "post",
        body,
      }),
      transformResponse: (
        response: GenericAPIResponse<CreateImportantCustomFieldResponse>
      ) => {
        return response.data;
      },
    }),

    // Update - PUT for important fields
    updateImportantCustomFields: builder.mutation<
      UpdateImportantCustomFieldResponse,
      UpdateImportantCustomFieldPayload
    >({
      query: (payload) => {
        const { id, ...body } = payload;
        return {
          url: `org-admin/customer-important-field-label/${id}`,
          method: "put",
          body,
        };
      },
      transformResponse: (
        response: GenericAPIResponse<UpdateImportantCustomFieldResponse>
      ) => {
        return response.data;
      },
    }),

    // Delete - DELETE for important fields

    deleteImportantCustomFields: builder.mutation<
      DeleteImportantCustomFieldResponse,
      number
    >({
      query: (id) => ({
        url: `/org-admin/customer-important-field-label/:important_label_id`,
        method: "delete",
      }),
      transformResponse: (
        response: GenericAPIResponse<DeleteImportantCustomFieldResponse>
      ) => {
        return response.data;
      },
    }),

    getAllPlans: builder.query<GetAllPlansResponse, void>({
      query: () => ({
        url: `/plans/all-available`,
      }),
      transformResponse: (
        response: GenericAPIResponse<GetAllPlansResponse>
      ) => {
        return response.data;
      },
    }),
  }),
});

export const {
  useVerifyInviteCodeMutation,
  useLoginMutation,
  useVerifyOtpMutation,
  useLogoutMutation,
  useUsersSyncQuery,
  useLazyUsersSyncQuery,
  useGetWabaTokensQuery,
  useGetOrgsQuery,
  useLazyGetOrgsQuery,
  useCancelOrderMutation,
  useDeleteOrgMutation,
  useGetPendingOrderQuery,
  useLazyGetPendingOrderQuery,
  useGetReservedNumberQuery,
  useGetSummariesQuery,
  useLazyGetSummariesQuery,
  useGetCallInsightsQuery,
  useGetOrgQuery,
  useGetOrgSettingsQuery,
  useUpdateOrgMutation,
  useCreateOrgMutation,
  useGetOrgSubscriptionsQuery,
  useGetOrgApiKeysQuery,
  useGetAllMembersQuery,
  useInviteMemberMutation,
  useChangeUserRoleMutation,
  useGetMemberQuery,
  useLazyGetMemberQuery,
  useGetStaffTableDataQuery,
  useGetPresenceOfMembersQuery,
  useLazyGetPresenceOfMembersQuery,
  useGetInvitationCountQuery,
  useGetPermissionsQuery,
  useRemoveMemberMutation,
  useCancelInviteMutation,
  useGetPresenceQuery,
  useLazyGetPresenceQuery,
  useGetCallRecordingQuery,
  useGetCallerTuneQuery,
  usePreviewCallerTuneMutation,
  useSetCallerTuneMutation,
  useGetCustomerQuery,
  useGetCustomerNotesQuery,
  useGetAllOrgsSettingsQuery,
  useUpdateOrgSettingsMutation,
  useGetTasksCategoriesQuery,
  useLazyGetCustomersQuery,
  useGetCustomerInsightsQuery,
  useGetTaskFiltersQuery,
  useLazyGetTaskFiltersQuery,
  useGetCustomerFiltersQuery,
  useDeleteTaskMutation,
  useUpdateTaskMutation,
  useCreateTaskMutation,
  useGetTasksQuery,
  useGetCustomersQuery,
  useGetTaskTypesQuery,
  useLazyGetCustomerQuery,
  useCustomerBulkUpdateMutation,
  useLazyGetInvitationCountQuery,
  useLazyGetCallInsightsQuery,
  useGetCallHistoryInsightsArrayQuery,
  useLazyGetCallerTuneQuery,
  useLazyGetAllMembersQuery,
  useUpdateContactMutation,
  useGetLeadStageQuery,
  useGetOrgLeadStagesQuery,
  useCreateLeadStageMutation,
  useUpdateLeadStageMutation,
  useDeleteLeadStageMutation,
  useGetLeadGroupQuery,
  useGetOrgLeadGroupsQuery,
  useCreateLeadGroupMutation,
  useUpdateLeadGroupMutation,
  useDeleteLeadGroupMutation,
  useUpdateUserMutation,
  useSearchNotesQuery,
  useLazySearchNotesQuery,
  useAddOrgApiKeyMutation,
  useDeleteOrgApiKeyMutation,
  useLazyGetCustomerFiltersQuery,
  useGetCategoriesQuery,
  useChangeLeadStagePositionMutation,
  useGetLeadStageTypesQuery,
  useGetLeadGroupTypesQuery,
  useGetOrgLabelsQuery,
  useLazyGetOrgLabelsQuery,
  useCreateOrgLabelMutation,
  useUpdateOrgLabelMutation,
  useDeleteOrgLabelMutation,
  useLoginOrgQuery,
  useLazyLoginOrgQuery,
  useGetCallHistoryQuery,
  useLazyGetCallHistoryQuery,
  useCustomerImportMutation,
  useGetCustomerCallHistoryQuery,
  useFaceBookLoginMutation,
  useFaceBookESMutation,
  useHeltarAccountMutation,
  useDragonflyAccountQuery,
  useGetFaceBookPagesQuery,
  useLinkFaceBookPageMutation,
  useDeleteContactMutation,
  useCreateContactMutation,
  useGetCustomerActivitiesQuery,
  useGetNoteQuery,
  useCreateNoteMutation,
  useGetOnboardingUIConfigQuery,
  useLazyGetOnboardingUIConfigQuery,
  useOnboardOrgMutation,
  useAcceptTNCMutation,
  useStartTrialMutation,
  useBookDemoMutation,
  useGetUserRecentDeviceQuery,
  useGetProductQuery,
  useGetProductsQuery,
  useClickToCallMutation,
  useGetTaskQuery,
  useGetOrgIntegrationSettingsQuery,
  useCreateOrgIntegrationMutation,
  useUpdateOrgIntegrationMutation,
  useDeleteOrgIntegrationMutation,
  useGetPublicURLQuery,
  useLazyGetPublicURLQuery,
  useGetFileDownloadMutation,
  useUpdateNoteMutation,
  useDeleteNoteMutation,
  useGetTaskInsightsQuery,
  useGetEmailIntegrationQuery,
  useGetEmailIntegrationsQuery,
  useGetEmailsQuery,
  useGetLastEmailQuery,
  useCreateEmailIntegrationMutation,
  useUpdateEmailIntegrationMutation,
  useGetAvailablePlansQuery,
  useLazyGetNumberDetailsQuery,
  useLazyGetReservedNumberQuery,
  useCreateTopupOrderMutation,
  useCreateProductMutation,
  useGetOrderQuery,
  useLazyGetOrderQuery,
  useGetGSTBusinessNameQuery,
  useLazyGetGSTBusinessNameQuery,
  useUpdateOrderMutation,
  useLazySearchAvailableNumbersQuery,
  useReserveNumberMutation,
  useCreatePurchaseOrderMutation,
  useCreateRenewOrderMutation,
  useCreateUpgradeOrderMutation,
  useGetSubscriptionPlanQuery,
  useGetLeadsQuery,
  useLazyGetLeadsQuery,
  useGetLeadsCountQuery,
  useLazyGetLeadsCountQuery,
  useDeleteLeadsMutation,
  useGetAllLeadsCountQuery,
  useGetEmailQuery,
  useLazyGetCustomerCallHistoryQuery,
  useGetFacebookFormQuery,
  useGetFacebookFormsQuery,
  useGetFacebookPageQuery,
  useGetMarketingIntegrationsQuery,
  useCreateMarketingIntegrationMutation,
  useUpdateMarketingIntegrationMutation,
  useLazyGetFaceBookPagesQuery,
  useLazyGetFacebookFormsQuery,
  useGetFacebookAccountQuery,
  useLazyGetFacebookAccountQuery,
  useLazyGetMarketingIntegrationsQuery,
  useGetAllFacebookFormsQuery,
  useLazyGetFacebookFormQuery,
  useGetAllIntegrationSettingsQuery,
  useGetAllLeadStagesQuery,
  useGetAllLeadGroupsQuery,
  useGetAllLabelsQuery,
  useGetIndiaMartLogsQuery,
  useGetFacebookLogsQuery,
  useGetActionSettingsQuery,
  useCreateActionSettingsMutation,
  useUpdateActionSettingsMutation,
  useGetAutomationLogsQuery,
  useLazyGetAutomationLogsQuery,
  useGetAllWhatsappTemplateVariablesQuery,
  useLazyGetInternalLeadStatusQuery,
  useCreateDailyTopupOrderMutation,
  useGetAllTalkTimeDetailsQuery,
  useGetCRMSettingsForOrgQuery,
  useGetCRMSettingsForAllOrgsQuery,
  useLazyGetCRMSettingsForAllOrgsQuery,
  useGetAllPlansQuery,
  useCustomerImportsMutation,
  useLazyImportRecentContactsQuery,
  useGetCustomFieldsQuery,
  useLazyGetCustomFieldsQuery,
  useCreateCustomFieldsMutation,
  useUpdateCustomFieldsMutation,
  useDeleteCustomFieldsMutation,
  useGetAllowedFieldsQuery,
  useGetImportantFieldsQuery,
  useCreateImportantFieldsMutation,
  useUpdateImportantCustomFieldsMutation,
  useDeleteImportantCustomFieldsMutation,
  useLazyGetAvailablePlansQuery,
} = api;
