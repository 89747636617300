export const countryCodes = [
  { code: "+91", label: "IND" },
  { code: "+86", label: "CN" },
  { code: "+7", label: "RU" },
  { code: "+92", label: "PK" },
  { code: "+61", label: "AU" },
  { code: "+977", label: "NP" },
  { code: "+66", label: "TH" },
  { code: "+60", label: "MY" },
  { code: "+81", label: "JP" },
  { code: "+1", label: "US" },
  { code: "+44", label: "GB" },
  { code: "+49", label: "DE" },
  { code: "+33", label: "FR" },
  { code: "+27", label: "ZA" },
];
